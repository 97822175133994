var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('simple-page', {
    scopedSlots: _vm._u([{
      key: "pageItem",
      fn: function fn() {
        return [_c('breadcrumb', {
          attrs: {
            "items": _vm.breadcrumbItems
          }
        }), _c('pending-items-internal', {
          attrs: {
            "title": false
          }
        })];
      },
      proxy: true
    }, {
      key: "pageTitle",
      fn: function fn() {
        return [_c('h1', [_vm._v("Pending Signatures - Members")]), _c('button', {
          staticClass: "clear-search",
          on: {
            "click": _vm.clearFilter
          }
        }, [_vm._v("Clear Search")])];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('pending-signature-filter', {
          attrs: {
            "filters": _vm.filterQuery
          },
          on: {
            "onSearch": _vm.filterData
          }
        })];
      },
      proxy: true
    }, {
      key: "topPagination",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center top-pagination"
        }, [_c('page-result', {
          attrs: {
            "rows": _vm.rows
          },
          on: {
            "update:rows": function updateRows($event) {
              _vm.rows = $event;
            }
          }
        }), _c('pagination', {
          attrs: {
            "rows": _vm.rows,
            "filters": _vm.filterQuery
          },
          on: {
            "update:rows": function updateRows($event) {
              _vm.rows = $event;
            }
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('clients-data', {
          attrs: {
            "memberLists": _vm.rows
          }
        }), _c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('page-result', {
          attrs: {
            "rows": _vm.rows
          },
          on: {
            "update:rows": function updateRows($event) {
              _vm.rows = $event;
            }
          }
        }), _c('pagination', {
          attrs: {
            "rows": _vm.rows,
            "filters": _vm.filterQuery
          },
          on: {
            "update:rows": function updateRows($event) {
              _vm.rows = $event;
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }