<template>
  <form @submit.prevent="searchPendingSignatures">
    <div class="form-group row">
      <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
        <label>First Name</label>
        <input
          v-model="filters.first_name"
          type="text"
          class="page-search-field"
          placeholder="Enter First Name"
        />
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
        <label>Last Name</label>
        <input
          v-model="filters.last_name"
          type="text"
          class="page-search-field"
          placeholder="Enter Last Name"
        />
      </div>
      <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-4">
        <label>Rep Code</label>
        <input
          v-model="filters.agent_code"
          type="text"
          class="page-search-field"
          placeholder="Enter Rep Code"
        />
      </div>
      <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-4">
        <label>Status</label>
        <select class="page-search-field" v-model="filters.status">
          <option value="">All</option>
          <option value="ACTIVE">Active</option>
          <option value="TERMED">Termed</option>
          <option value="WITHDRAWN">Withdrawn</option>
          <option value="PENDING">Pending</option>
        </select>
      </div>
      <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-4">
        <label>State</label>

        <multiselect
          name="state"
          v-model="filters.state"
          placeholder="Select State"
          :options="stateList.map((type) => type.value)"
          :custom-label="(opt) => stateList.find((x) => x.value == opt).text"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
        <label>Enrollment Date</label>
        <div class="enrollmentDate-picker">
          <date-picker
            @input="setToDate"
            input-class="page-search-field"
            placeholder="From"
            type="date"
            v-model="filters.enrollment_from"
            value-type="format"
            :disabled-date="
              (date) => {
                return disabledAfterCurrentDate(date, 'start');
              }
            "
          ></date-picker>

          <date-picker
            input-class="page-search-field"
            placeholder="To"
            type="date"
            v-model="filters.enrollment_to"
            value-type="format"
            :disabled="disableDate"
            :disabled-date="
              (date) => {
                return disabledAfterCurrentDate(date, 'end');
              }
            "
          ></date-picker>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
        <label>View Downline Clients For</label>
        <multiselect
          name="downline"
          v-model="filters.rep_id"
          placeholder="Select Rep"
          :options="downlineReps.map((type) => type.id)"
          :custom-label="
            (opt) =>
              downlineReps.find((x) => x.id == opt).first_name +
              ' ' +
              downlineReps.find((x) => x.id == opt).last_name
          "
          :options-limit="100"
          :internal-search="false"
          :local-search="false"
          @search-change="searchDownlineHandler"
          @open="getDownline"
        />
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
        <label>View by Group</label>
        <multiselect
          name="group"
          v-model="filters.group_id"
          placeholder="Select Group"
          :options="groups.map((type) => type.group_id)"
          :custom-label="
            (opt) => groups.find((x) => x.group_id == opt).group_name
          "
          :options-limit="100"
          :internal-search="false"
          :local-search="false"
          @search-change="searchHandler"
          @open="getGroups"
        />
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
        <label>Effective Date</label>
        <div class="effective_date">
          <date-picker
            input-class="page-search-field"
            placeholder="Effective date"
            type="date"
            v-model="filters.effective_date"
            value-type="format"
          ></date-picker>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-end">
      <div class="col-xxl-2 col-xl-3">
        <button type="submit" class="univ-btn">Search</button>
      </div>
    </div>
  </form>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  name: "PendingSignatureFilter",
  props: {
    filters: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      stateList: Helper.getStates(),
      groups: [],
      downlineReps: [],
      disableDate: true,
    };
  },
  created() {
    this.getGroups();
    this.getDownline();
  },
  methods: {
    searchPendingSignatures() {
      this.$emit("onSearch", this.filters);
    },
    searchHandler(query) {
      if (query.length > 2) {
        this.getGroups(query);
      }
    },
    searchDownlineHandler(query) {
      if (query.length > 2) {
        this.getDownline(query);
      }
    },
    getGroups(query = "") {
      let url;
      if (query) {
        url = `v2/get-rep-group-list?size=100&group_name=${query}`;
      } else {
        url = `v2/get-rep-group-list?size=100`;
      }
      Api.get(url).then((res) => {
        this.groups = res.data.data;
      });
    },
    getDownline(query = "") {
      let url;
      if (query) {
        url = `v2/get-rep-downline?size=100&rep_name=${query}`;
      } else {
        url = `v2/get-rep-downline?size=100`;
      }
      Api.get(url).then((res) => {
        this.downlineReps = res.data.data;
      });
    },
    disabledAfterCurrentDate(date, type) {
      const formattedDate = new Date(new Date().setHours(0, 0, 0, 0));
      if (type == "end") {
        if (
          date <
          new Date(new Date(this.filters.enrollment_from).setHours(0, 0, 0, 0))
        ) {
          return true;
        }
      }
      return (
        date >
        formattedDate.setDate(formattedDate.getDate() - +(type == "start"))
      );
    },
    setToDate(date) {
      if (date) {
        this.disableDate = false;
      } else {
        this.filters.enrollment_to = "";
        this.disableDate = true;
      }
    },
  },
};
</script>