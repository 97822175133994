<template>
  <simple-page>
    <template v-slot:pageItem>
      <breadcrumb :items="breadcrumbItems" />
      <pending-items-internal :title="false" />
    </template>
    <template v-slot:pageTitle>
      <h1>Pending Signatures - Members</h1>
      <button class="clear-search" @click="clearFilter">Clear Search</button>
    </template>
    <template v-slot:filter>
      <pending-signature-filter :filters="filterQuery" @onSearch="filterData" />
    </template>
    <template v-slot:topPagination>
      <div
        class="d-flex justify-content-between align-items-center top-pagination"
      >
        <page-result :rows.sync="rows" />
        <pagination :rows.sync="rows" :filters="filterQuery" />
      </div>
    </template>
    <template v-slot:table>
      <clients-data :memberLists="rows" />
      <div class="d-flex justify-content-between align-items-center">
        <page-result :rows.sync="rows" />
        <pagination :rows.sync="rows" :filters="filterQuery" />
      </div>
    </template>
  </simple-page>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import ClientsData from "@/views/MemberList/partials/ClientsData.vue";
import PendingItemsInternal from "../Dashboard/partials/PendingItemsInternal.vue";
import PendingSignatureFilter from "./partials/PendingSignatureFilter.vue";
export default {
  components: { PendingItemsInternal, PendingSignatureFilter, ClientsData },
  name: "PendingSignatureList",
  data: () => ({
    rows: [],
    columns: [
      { label: "System Id" },
      { label: "Name" },
      { label: "Dates" },
      { label: "Rep & Employee" },
      { label: "Premiums" },
      { label: "Actions" },
    ],
    filterQuery: {
      first_name: "",
      last_name: "",
      agent_code: "",
      status: "",
      enrollment_date: "",
      state: "",
      effective_date: "",
      enrollment_from: "",
      enrollment_to: "",
      group_id: "",
      rep_id: "",
      page_size: 30,
    },
    breadcrumbItems: [{ title: "Signature", active: true }],
  }),
  methods: {
    clearFilter() {
      this.filterQuery = {
        first_name: "",
        last_name: "",
        agent_code: "",
        status: "",
        enrollment_date: "",
        state: "",
        effective_date: "",
        enrollment_from: "",
        enrollment_to: "",
        group_id: "",
        rep_id: "",
        page_size: 30,
      };
      this.getPendingSignatures();
    },
    filterData(filters) {
      this.filterQuery = filters;
      this.getPendingSignatures(this.filterQuery);
    },
    getPendingSignatures(filters = null) {
      let url;
      let loader = this.$loading.show();

      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `v2/get-pending-uploads-signature-details?${filterUrl}`;
      } else {
        url = `v2/get-pending-uploads-signature-details?page_size=30`;
      }

      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getPendingSignatures();
  },
};
</script>
<style src="@/assets/css/viewclients.css"></style>
